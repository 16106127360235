exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-american-bully-tsx": () => import("./../../../src/pages/american-bully.tsx" /* webpackChunkName: "component---src-pages-american-bully-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loja-virtual-tsx": () => import("./../../../src/pages/loja-virtual.tsx" /* webpackChunkName: "component---src-pages-loja-virtual-tsx" */),
  "component---src-pages-servicos-markdown-remark-frontmatter-path-tsx": () => import("./../../../src/pages/servicos/{markdownRemark.frontmatter__path}.tsx" /* webpackChunkName: "component---src-pages-servicos-markdown-remark-frontmatter-path-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */),
  "component---src-pages-shih-tzu-tsx": () => import("./../../../src/pages/shih-tzu.tsx" /* webpackChunkName: "component---src-pages-shih-tzu-tsx" */),
  "component---src-pages-spitz-alemao-tsx": () => import("./../../../src/pages/spitz-alemao.tsx" /* webpackChunkName: "component---src-pages-spitz-alemao-tsx" */)
}

